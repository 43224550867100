import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel, Image } from 'react-bootstrap';
import Message from './Message';
import { useGetProductDetailsQuery } from '../slices/productsApiSlice';

const ImageCarousel = () => {
  const {id: productId} = useParams();
  const {
    data: product,
    isLoading,
    refetch,
    error,
} = useGetProductDetailsQuery(productId);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      {isLoading ? null : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <Carousel
          activeIndex={activeIndex}
          onSelect={handleSelect}
          pause='hover'
          className='bg-primary mb-4'
        >
          <Carousel.Item>
            <Image src={product.image} alt={product.name} fluid />
          </Carousel.Item>
          <Carousel.Item>
            <Image src={product.image1} alt={product.name} fluid />
          </Carousel.Item>
          <Carousel.Item>
            <Image src={product.image2} alt={product.name} fluid />
          </Carousel.Item>
          <Carousel.Item>
            <Image src={product.image3} alt={product.name} fluid />
          </Carousel.Item>
        </Carousel>
      )}
      {/* Thumbnails */}
      <div className='thumbnail-container'>
        <div className="thumbnail-box">
          <div
            onClick={() => handleThumbnailClick(0)}
            className={`thumbnail ${activeIndex === 0 && 'active'}`}
          >
            <img src={product?.image} alt={product.name} />
          </div>
        </div>
        <div className="thumbnail-box">
          <div
            onClick={() => handleThumbnailClick(1)}
            className={`thumbnail ${activeIndex === 1 && 'active'}`}
          >
            <img src={product?.image1} alt={product.name} />
          </div>
        </div>
        <div className="thumbnail-box">
          <div
            onClick={() => handleThumbnailClick(2)}
            className={`thumbnail ${activeIndex === 2 && 'active'}`}
          >
            <img src={product?.image2} alt={product.name} />
          </div>
        </div>
        <div className="thumbnail-box">
          <div
            onClick={() => handleThumbnailClick(3)}
            className={`thumbnail ${activeIndex === 3 && 'active'}`}
          >
            <img src={product?.image3} alt={product.name} />
          </div>
        </div>
      </div>


    </>
  );
};

export default ImageCarousel;
